<template>
  <div class="infoPage">
    <div class="selectBox">
      <div class="selectBtn">
        <div :class="{'btn':true,'active':selectActive == '1'}" @click="selectClick('1')">{{ tType=='CH'?'休闲消费':'leisure consumption' }}</div>
        <div :class="{'btn':true,'active':selectActive == '2'}" @click="selectClick('2')">{{ tType=='CH'?'非遗文化':'Intangible cultural heritages' }}</div>
      </div>
    </div>

    <div class="conBoxList">
      <!--      <div class="list">-->
      <div class="item" v-for="(item,index) in datalist" :key="item.sightseeingId" @click="go(item.productId)">
        <div class="img scale">
          <img :src="item.productCover" alt="">
          <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
        </div>
<!--        <div class="box-1-1">
          <div class="box-1-1-1">
            <div class="box-1-1-1-1">{{ item.webTitle }}</div>
          </div>
          <div class="box-1-1-2">
            <div v-for="items in item.tags"  class="box-1-1-2-1">{{ items }}</div>
          </div>
          <div class="box-1-1-3">{{item.webDesc}}</div>
        </div>-->
        <div class="con">
          <div class="tit">
            {{ item.productTitle }}
          </div>
          <div class="text2"><span v-for="(items,indexs) in item.tags">{{items}}</span></div>
          <div class="conText">
            {{ item.productDesc }}
          </div>
        </div>
      </div>
      <!--      </div>-->
    </div>

    <!--    <div class="paging-device">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              :page-size="per_page"
              :total="totalCount">
          </el-pagination>
        </div>-->
  </div>
</template>

<script>
import {getproduct} from '@/api/table'
export default {
  name: "lishi",
  components:{
  },
  data(){
    return {
      // imgURL,
      url:'',
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      datalist:[],
      selectActive:'1',
      tType: 'CH'
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.createlist()
  },
  methods:{
    // 每页条数
    // handleSizeChange(val) {
    //   this.per_page=val
    //   this.createlist()
    // },
    // // 页数
    // handleCurrentChange(val) {
    //   this.current_page=val
    //   this.createlist()
    // },
    async createlist(){
      await getproduct({
        productType: this.selectActive,   // 必填 类型 1休闲消费 2非遗文创
        // pageNum:this.current_page,
        // pageSize:this.per_page
      }).then(res=>{
        // console.log(res)
        if (res.code==200) {
          this.datalist=res.rows
          this.datalist.forEach((item,index)=>{
            // console.log(item.tags)
            if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
          })
          // this.totalCount=res.total
        }
      })
    },
    selectClick(num){
      this.selectActive = num
      this.createlist()
    },
    go(id){
      this.$router.push({path:'/featureConsumptionInfo',query:{id,type:'27'}})
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  min-height: 800px;
  //padding: 0 0 0 55px;
  overflow: hidden;
  .selectBox{
    width: 1200px;
    margin: 50px auto 0;
  }
  .selectBtn{
    //padding: 5px;
    //border-radius: 35px;
    //border: 1px solid #D3D3D3;
    display: flex;
    width: fit-content;
    .btn{
      padding: 16px 5px;
      font-size: 20px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;
      color: #333333;
      //border-radius: 30px;
      cursor: pointer;
      margin-left: 30px;
      &:nth-child(1){
        margin-left: 0px;
      }
    }
    .active{
      //color: #FFFFFF;
      color: #007B8A;
      //background: #007B8A;
      border-bottom: 5px solid #007B8A;
    }
  }
  .conBoxList{
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: auto;
    padding: 60px 0 120px 0;
    .item{
      flex: none;
      width: 380px;
      background: #FFFFFF;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      margin-left: 30px;
      margin-bottom: 60px;
      &:nth-child(3n+1){
        margin-left: 0;
      }
      &:hover{
        .img{
          transition:all 1000ms ease;
          //transform: scale(1);
          transform: scale(1.1);
        }
        .box-1-1 .box-1-1-1 .box-1-1-1-1{
          color: #337D8D;
        }
        .con>.tit{
          color: #337D8D;
        }
      }
      .img{
        width: 100%;
        height: 300px;
        position: relative;
        transition:all 500ms ease;
        img{
          width: 100%;
          height: 100%;
        }
        .eye{
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 8px 12px;
          background: #337D8D55;
          border-radius: 5px;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .box-1-1{
        padding: 24px;
        box-sizing: border-box;
        .box-1-1-1{
          display: flex;
          .box-1-1-1-1{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
          }
          .box-1-1-1-2{
            font-size: 20px;
            color: #F2BE00;
            margin-left: 10px;
          }
        }
        .box-1-1-2{
          display: flex;
          margin: 10px 0;
          .box-1-1-2-1{
            background: rgba(0, 123, 138, 0.1);
            border-radius: 20px;
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #007B8A;
            padding: 4px 12px;
            box-sizing: border-box;
            margin-right: 10px;
          }
        }
        .box-1-1-3{
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #999999;
          /* 在恰当的断字点进行换行 */
          word-break: break-all;
          /* 超出范围隐藏 */
          overflow: hidden;
          /* 文字超出用省略号 */
          text-overflow: ellipsis;
          /* 盒子模型 */
          display: -webkit-box;
          /* 显示的文本行数 */
          -webkit-line-clamp: 2;
          /* 子元素的垂直排列方式 */
          -webkit-box-orient: vertical;
        }
      }
      .con{
        padding: 24px;
        .tit{
          font-size: 20px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; //行数
          overflow: hidden;
        }
        .text2{
          margin: 15px 0 10px;
          span{
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            padding: 3px 13px 4px 12px;
            margin-left: 10px;
            background: rgba(120, 120, 120, 0.1);
            border-radius: 17px;
            &:nth-child(1){
              margin-left: 0;
            }
          }
        }
        .conText{
          margin-top: 10px;
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //行数
          overflow: hidden;
        }
      }

    }
  }


}
</style>

